import { Injectable } from '@angular/core';
import { ConfigService } from '../core/config/config.service';
import { UsertypeService } from '../core/usertype/usertype.service';
import { LinkCondition } from '../model/link.model';
import { combineLatest } from 'rxjs';
import {map} from 'rxjs/operators'
import { Claim, Profile, ProfileService } from '../core/profile/profile.service';
import { TranslocoService } from '@ngneat/transloco';
import { defaultCountryPerLangType, lookupCountry,defaultCountryPerLang } from '../core/util/lookup-default-country.util';
import { HeaderVisibilityService } from '../core/header-visibility/header-visibility.service';
import { CoreModule, LoggerService } from '../core';
import { userTypes, whiteLabelNames } from 'src/app/appsettings';
import { Config } from '../model/config.model';
const hasAny = (req:string[],ut: string[]) => ut.some(u => req.indexOf(u) > -1)
const has = (req: string[], ut: string[]) => req.every(r => ut.indexOf(r) > -1)
const hasTrueClaim = (c: string, req: Claim[]) => req && req.find((x) => x.claimType === c)?.claimValue?.toLocaleLowerCase() == "true"
const hasClaim = (c: string, v: string, req: Claim[]) => req && req.find((x) => x.claimType === c)?.claimValue?.toLocaleLowerCase() == v
const langCodeQueryStr = (link:string, lang:string, countries:defaultCountryPerLangType) => `${link}?langCode=${lang}-${lookupCountry(lang, countries).toUpperCase()}`

const countryPerLang: defaultCountryPerLangType = {
  ...defaultCountryPerLang,
  en: 'gb',
}

@Injectable({
  providedIn: CoreModule
})
export class SynlabHeaderService {
  links$ = combineLatest([
    this.usertypeService.usertypes$,
    this.configService.config$,
    this.profileService.profile$,
    this.translocoService.langChanges$
  ]).pipe(
    map(([ut, c, p, lang]) => this.linkCondition
      .filter(lc => lc.condition(ut, c, p))
      .map(lc => ({
        ...lc,
        url: lc.urlGenerator ? lc.urlGenerator(c, lang) : lc.url
      }))),
  );

  user$ = this.profileService.profile$;

  myAccountLink$ = combineLatest([
    this.configService.config$,
    this.translocoService.langChanges$
  ]).pipe(map(([c, lang]) => c.baseProfileUrl + langCodeQueryStr('', lang, countryPerLang)));

  brandLogoLink$ = combineLatest([
    this.configService.config$,
    this.translocoService.langChanges$
  ]).pipe(map(([c, lang]) => c.baseBookingUrl + langCodeQueryStr('', lang, countryPerLang)));

  visible$ = this.headerVisibilityService.visible$;

  config$ = this.configService.config$;

  constructor(
    private usertypeService: UsertypeService,
    private configService: ConfigService,
    private profileService: ProfileService,
    private headerVisibilityService: HeaderVisibilityService,
    private translocoService: TranslocoService,
    private logr: LoggerService
  ) { }

  private isMenuLinkNotHidden(config: Config): boolean {
    if (['plasma-web', 'plasmaui-code-pkce'].includes(config.applicationId)) { return false; }

    const isEnableCheckResults = config.EnableCheckResults
    if (!isEnableCheckResults) { return true; }
    return false;
  }

  private linkCondition: LinkCondition[] = [
    {
      label: 'bigCommerceLabel',
      icon: '/assets/synlab-header/panel.svg',
      urlGenerator: (c, lang) => {
        if (this.isMenuLinkNotHidden(c)) return false;
        if (c.WhiteLabelName === whiteLabelNames.CZECH_REPUBLIC
          || c.WhiteLabelName === whiteLabelNames.BELGIUM
        ) return false;

        let linksPerLang = {};
        c.baseBigcommerceUrl?.split(',').map(u => u.split('|')).forEach(l => {
          const [locale, url] = l;
          linksPerLang[locale] = url;
        });
        if (lang in linksPerLang) {
          return linksPerLang[lang];
        }
        return linksPerLang["**"] ?? c.baseBigcommerceUrl;
      },
      condition: (ut, c) => has(['synlab_access_user'], ut)
    },
    {
      label: 'ordersBookingLabel',
      icon: '/assets/synlab-header/panel.svg',
      urlGenerator: (c, lang) => {
        if (this.isMenuLinkNotHidden(c)) return false;
        if (c.WhiteLabelName === whiteLabelNames.CZECH_REPUBLIC
          || c.WhiteLabelName === whiteLabelNames.BELGIUM
        ) return false;

        const isFITenant = c.WhiteLabelEnabled && c.WhiteLabelName == whiteLabelNames.FINLAND;
        const isHUTenant = c.WhiteLabelEnabled && c.WhiteLabelName == whiteLabelNames.HUNGARY;
        if (isHUTenant || isFITenant) { return c.baseBookingUrl; }

        const isHungary = c.WhiteLabelName == whiteLabelNames.HUNGARY;
        if (isHungary && c.WhiteLabelEnabled) {
          return c.baseBookingUrl
        }

        let linksPerLang = {};
        c.eeBaseBookingUrl.split(',').map(u => u.split('|')).forEach(l => {
          const [locale, url] = l;
          linksPerLang[locale] = url;
        });
        if (lang in linksPerLang) {
          return linksPerLang[lang];
        }
        return linksPerLang["*"] ?? c.eeBaseBookingUrl;
      },
      condition: (ut, c) => has(['synlab_access_user'], ut) && !this.isMenuLinkNotHidden(c)
    },
    {
      label: "resultsCertificateLabel",
      icon: '/assets/synlab-header/results.svg',
      urlGenerator: (c, lang) => `${c.basePlasmaUrl}/results/category` + langCodeQueryStr('', lang, countryPerLang),
      condition: (ut,c) => {
        if (this.isMenuLinkNotHidden(c)) { return false; }

        return has(['synlab_access_user'],ut) && c.FullCatalogueViewEnabled === true && c.applicationId == 'sts-accounts';
      }
    },
    {
      label: "resultsCertificateLabel",
      icon: '/assets/synlab-header/results.svg',
      route: '/results',
      condition: (ut,c) => {
        if (this.isMenuLinkNotHidden(c)) { return false; }

        return has(['synlab_access_user'],ut) && c.FullCatalogueViewEnabled === true && c.applicationId == 'plasma-web';
      }
    },
    {
      label: "checkResultLabel",
      icon: '/assets/synlab-header/results.svg',
      urlGenerator: (c, lang) => `${c.basePlasmaUrl}/results/category` + langCodeQueryStr('', lang, countryPerLang),
      condition: (userTypes: string[], config: Config, profile: Profile) => {
        if (this.isMenuLinkNotHidden(config)) { return false; }

        return hasAny(['ee_admin', 'pt_admin'], userTypes)
          && config.FullCatalogueViewEnabled === true
          && hasTrueClaim('allow_delete_user', profile.claims)
          && config.applicationId == 'sts-accounts';
      }
    },
    {
      label: "checkResultLabel",
      icon: '/assets/synlab-header/results.svg',
      route: '/results/category',
      condition: (userTypes: string[], config: Config, profile: Profile) => {
        if (this.isMenuLinkNotHidden(config)) { return false; }

        return hasAny(['ee_admin', 'pt_admin'], userTypes)
          && config.FullCatalogueViewEnabled === true
          && hasTrueClaim('allow_delete_user', profile.claims)
          && config.applicationId == 'plasma-web';
      }
    },
    {
      label: "preventionWellness",
      icon: '/assets/synlab-header/heart.svg',
      urlGenerator: (c, lang) => `${c.basePlasmaUrl}/prevention-wellness` + langCodeQueryStr('', lang, countryPerLang),
      condition: (ut,c,p) =>
        hasAny(['synlab_access_user'],ut)
        && c.EnablePreventionWellness === true
        && !hasTrueClaim('anonymous_user', p.claims)
        && c.applicationId == 'sts-accounts'
    },
    {
      label: "preventionWellness",
      icon: '/assets/synlab-header/heart.svg',
      route: '/prevention-wellness',
      condition: (ut,c,p) =>
        hasAny(['synlab_access_user'],ut) && c.EnablePreventionWellness === true && !hasTrueClaim('anonymous_user', p.claims) && c.applicationId == 'plasma-web'
    },
    {
      label: "adminLabel",
      icon: '/assets/synlab-header/people.svg',
      urlGenerator: (c,lang) => langCodeQueryStr(c.baseAdminUrl, lang, countryPerLang),
      condition: (ut,c) => hasAny(['uk_shfy_admin', 'uk_dfp_admin', 'ee_admin','notification_user', 'superadmin', 'pt_admin'],ut)
    },
    {
      label: "bookingAdminLabel",
      icon: '/assets/synlab-header/people.svg',
      urlGenerator: (c,lang) => langCodeQueryStr(c.baseBookingAdminUrl, lang, countryPerLang),
      condition: (ut,c) => {
        const tenantName: whiteLabelNames = c.WhiteLabelName as any;
        return hasAny([
          userTypes.BOOKING_ADMIN_BCP_COORDINATOR,
          userTypes.BOOKING_ADMIN_BCP_CLERK,
          userTypes.BOOKING_ADMIN], ut) && [whiteLabelNames.HUNGARY, whiteLabelNames.FINLAND].includes(tenantName);
      }
    },
    {
      label: "webreqLabel",
      icon: '/assets/synlab-header/people.svg',
      urlGenerator: (c, lang) => langCodeQueryStr(c.baseWebreqUrl, lang, countryPerLang),
      condition: (ut,c) => hasAny(['bcp','webreq_user', 'webreq_admin', 'webreq_finance'],ut)
    },
    {
      label: "webreqLabelAdmin",
      icon: '/assets/synlab-header/people.svg',
      urlGenerator: (c, lang) => langCodeQueryStr(c.baseWebreqAdminUrl, lang, countryPerLang),
      condition: (ut,c) => hasAny(['webreq_superadmin'],ut)
    },
    {
      label: "labShopLabel",
      urlGenerator: (c, lang) => langCodeQueryStr(c.baseBookingUrl, lang, countryPerLang),
      condition: (ut,c) => {
        const isESTenant = c.WhiteLabelEnabled && c.WhiteLabelName === whiteLabelNames.SPAIN;
        this.logr.log({
          WhiteLabelEnabled: c.WhiteLabelEnabled,
          WhiteLabelName: c.WhiteLabelName,
          isSpain: c.WhiteLabelName === whiteLabelNames.SPAIN,
          hasAny: hasAny(['webshop_admin', 'webshop_user'], ut) });
        if (isESTenant && hasAny(['webshop_admin', 'webshop_user'], ut)) { return true; }
        return false;
      }
    },
    {
      label: "medioLabel",
      urlGenerator: (c, lang) => langCodeQueryStr('https://medicall.cc', lang, countryPerLang),
      condition: (ut,c) => {
        const isEsTcTenant = c.WhiteLabelEnabled && c.WhiteLabelName === whiteLabelNames.SPAIN_TELECOUNSELING;
        if (isEsTcTenant && hasAny(['pt_admin', 'synlab_access_user', 'expert'], ut)) { return true; }
        return false;
      }
    }
  ]
}
