import { BE_CONFIG } from "./be";
import { CZ_CONFIG } from "./cz";
import { FI_CONFIG } from "./fi";
import { HU_CONFIG } from "./hu";
import { FormConfigInterface } from "./interfaces/form-config.interface";
import { ES_TC_CONFIG } from "./ts-tc";

export const FORM_CONFIG: Record<string, FormConfigInterface> = {
  "es-tc": ES_TC_CONFIG,
  hu: HU_CONFIG,
  fi: FI_CONFIG,
  cz: CZ_CONFIG,
  be: BE_CONFIG,
};
