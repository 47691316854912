export function isNewErrorSchema(inputError: any): boolean {
  return (
    typeof inputError === 'object' &&
    inputError !== null &&
    'success' in inputError &&
    'message' in inputError &&
    'data' in inputError &&
    'errorCode' in inputError &&
    'errors' in inputError
  );
}
