import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UnauthorizedComponent } from '../modules/shared/unauthorized/unauthorized.component';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private readonly matDialog: MatDialog,
    public readonly toastrService: ToastrService,
  ) { }

  handleError(error: any): void {
    // Log the error
    console.error("HTTP Error:", error);

    // Handle specific HTTP status codes
    if (error.status === 401) {
      this.handleUnauthorized();
    } else if (error.status === 403) {
      this.handleForbidden();
    } else if (error.status === 404) {
      this.handleNotFound(error.error);
    } else if (error.status >= 500) {
      this.handleServerError(error.error);
    } else {
      this.handleGenericError(error.error);
    }
  }

  private handleNotFound(error: any): void {
    this.toastrService.error(error.message, 'Resource not found.', { timeOut: 5000 });
  }

  private handleServerError(error: any): void {
    this.toastrService.error(error.message, 'Server error occurred.', { timeOut: 5000 });
  }

  private handleGenericError(error: any): void {
    this.toastrService.error(error.message, 'An unexpected error occurred.', { timeOut: 5000 });
  }

  protected handleUnauthorized(): void {
    this.matDialog.open(UnauthorizedComponent, { disableClose: true });
  }

  protected handleForbidden(): void {
    this.matDialog.open(UnauthorizedComponent, { disableClose: true });
  }

}
