import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { UnauthorizedComponent } from "src/app/modules/shared/unauthorized/unauthorized.component";
import { TranslocoService } from "@ngneat/transloco";
import { ApiService } from "../../api/api.service";
import { MatDialog } from "@angular/material/dialog";
import { ErrorHandlerService } from "../../error-handler.service";
import { isNewErrorSchema } from "src/app/utils/is-new-error-schema";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  private session = [];
  private localSession = [];
  constructor(
    private dialog: MatDialog,
    private transloco: TranslocoService,
    private api: ApiService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.session = Object.keys(sessionStorage);
    let user = null;
    let tokenJson = null;

    if (this.session.length) {
      user = this.session.find((key) => key.indexOf("oidc.user:") > -1);

      if (user) tokenJson = JSON.parse(sessionStorage.getItem(user));
    }

    if (user == null) {
      // refer to local storage when session was not found
      // used to transfer session
      this.localSession = Object.keys(localStorage);

      if (this.localSession.length) {
        user = this.localSession.find((key) => key.indexOf("session-transfer") > -1);

        if (user) {
          const userSessionToken = JSON.parse(localStorage.getItem(user));
          tokenJson = {
            access_token: userSessionToken.accessToken,
            token_type: "Bearer",
          };
        }
      }
    }

    request = request?.clone({
      // withCredentials: true,
      setHeaders: {
        Authorization: tokenJson ? `${tokenJson.token_type} ${tokenJson.access_token}` : "",
        "Accept-Language": this.getActiveLanguage(),
      },
    });

    return next.handle(request).pipe(
      catchError((error) => {
        if (isNewErrorSchema(error.error)) {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        } else if (error.status === 401 || error.status === 403) {
          setTimeout(() => {
            this.dialog.open(UnauthorizedComponent, { disableClose: true });
            console.log(error.status);
          }, 1050);
        }
        return throwError(error);
      })
    );
  }

  private getActiveLanguage(): string {
    let activeLang = this.transloco.getActiveLang().toLowerCase();
    return this.api.formatAcceptLanguage(activeLang);
  }
}
